import React from "react";

const SvgLogo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 800 600"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: "2",
      }}
    >
      <rect
        id="Artboard1"
        x="0"
        y="0"
        width="800"
        height="600"
        style={{ fill: "none" }}
      />
      <g id="Discord">
        <g>
          <path
            d="M468.844,366.182c-0,0 -4.751,4.009 -4.956,8.863c-0.207,4.853 -5.18,27.207 -10.757,59.493c3.222,-0.953 5.956,-8.118 7.933,-17.027c12.811,13.372 30.214,21.899 51.519,18.554c-13.103,-8.243 -24.544,-16.867 -34.473,-26.156c41.865,26.16 88.231,36.075 128.915,1.056c-54.112,-0.233 -98.817,-9.774 -138.656,-31.374c73.298,28.194 166.49,42.989 203.278,-35.578c-64.981,29.67 -122.093,39.053 -181.969,26.908c90.127,2.247 224.504,-15.173 228.629,-125.552c-61.849,68.868 -124.701,104.579 -206.949,112.852c107.408,-34.504 254.242,-109.052 217.155,-239.396c-42.088,95.943 -73.486,149.842 -179.764,208.723l-0.024,0.008c90.795,-68.213 208.479,-188.951 130.971,-300.72c-13.683,158.724 -66.207,249.355 -210.852,339.346Z"
            style={{ fill: "#be2134", fillRule: "nonzero" }}
          />
          <path
            d="M251.275,327.556l-0.025,-0.008c-106.277,-58.881 -137.675,-112.78 -179.763,-208.723c-37.087,130.344 109.748,204.892 217.155,239.396c-82.248,-8.273 -145.1,-43.984 -206.949,-112.852c4.126,110.379 138.502,127.799 228.63,125.552c-59.876,12.145 -116.988,2.762 -181.969,-26.908c36.787,78.567 129.98,63.772 203.278,35.578c-39.84,21.6 -84.544,31.141 -138.656,31.374c40.684,35.019 87.049,25.104 128.915,-1.056c-9.93,9.289 -21.371,17.913 -34.473,26.156c21.305,3.345 38.708,-5.182 51.519,-18.554c1.976,8.909 4.711,16.074 7.933,17.027c-5.577,-32.286 -15.208,-57.096 -15.413,-61.949c-0.206,-4.854 -4.956,-8.863 -4.956,-8.863c-144.645,-89.991 -192.513,-178.166 -206.196,-336.89c-77.509,111.769 40.174,232.507 130.97,300.72Z"
            style={{ fill: "#be2134", fillRule: "nonzero" }}
          />
          <path
            d="M261.58,451.354l34.536,44.541l26.199,-46.196l-30.368,-51.84l-30.367,53.495Z"
            style={{ fill: "url(#_Radial1)", fillRule: "nonzero" }}
          />
          <path
            d="M476.411,449.325l26.199,46.197l34.537,-44.541l-30.368,-53.495l-30.368,51.839Z"
            style={{ fill: "url(#_Radial2)", fillRule: "nonzero" }}
          />
          <path
            d="M466.659,432.198l24.317,42.405c11.486,20.03 11.808,30.371 53.353,37.446l30.161,4.99l-84.279,-148.49c-5.499,20.923 -13.346,42.359 -23.552,63.649Z"
            style={{ fill: "#e3ab37", fillRule: "nonzero" }}
          />
          <use
            x="478.486"
            y="399.853"
            width="60.735"
            height="95.432"
            transform="matrix(0.995664,0,0,0.99408,0,0)"
          />
          <path
            d="M224.253,517.039l30.161,-4.99c41.995,-7.63 41.867,-17.416 53.353,-37.446l24.318,-42.405c-10.206,-21.29 -18.054,-42.726 -23.553,-63.649l-84.279,148.49Z"
            style={{ fill: "#e3ab37", fillRule: "nonzero" }}
          />
          <use
            x="262.736"
            y="401.391"
            width="60.735"
            height="98.037"
            transform="matrix(0.995664,0,0,0.990269,0,0)"
          />
          <path
            d="M537.482,290.002l-7.024,1.537c-14.627,3.201 -27.324,12.465 -35.102,25.609l-91.037,153.84c-2.25,3.802 -7.765,3.802 -10.015,0l-90.479,-152.894c-8.136,-13.749 -21.415,-23.441 -36.713,-26.795l-5.728,-1.257l-35.254,-3.702l166.1,282.708c3.215,5.488 10.948,5.488 14.164,-0l165.794,-282.794l-34.706,3.748Z"
            style={{ fill: "#e3ab37", fillRule: "nonzero" }}
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="_Radial1"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(259.611,282.028,-274.034,267.184,382.769,277.132)"
        >
          <stop offset="0" style={{ stopColor: "#f9ee1c", stopOpacity: "1" }} />
          <stop offset="0" style={{ stopColor: "#f9ee1c", stopOpacity: "1" }} />
          <stop
            offset="0.86"
            style={{ stopColor: "#faaf43", stopOpacity: "1" }}
          />
          <stop offset="1" style={{ stopColor: "#faaf43", stopOpacity: "1" }} />
        </radialGradient>
        <radialGradient
          id="_Radial2"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(39.9419,-3.55271e-15,3.55271e-15,41.107,506.779,446.504)"
        >
          <stop offset="0" style={{ stopColor: "#f9ee1c", stopOpacity: "1" }} />
          <stop offset="0" style={{ stopColor: "#f9ee1c", stopOpacity: "1" }} />
          <stop
            offset="0.86"
            style={{ stopColor: "#faaf43", stopOpacity: "1" }}
          />
          <stop offset="1" style={{ stopColor: "#faaf43", stopOpacity: "1" }} />
        </radialGradient>
        <image id="_Image3" width="61px" height="96px" />
        <image id="_Image4" width="61px" height="99px" />
      </defs>
    </svg>
  );
};

export default SvgLogo;
